/* modal login */
.sub_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub_header * {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--main_color) !important;
  text-decoration: none;
}

.register_btn {
  font-weight: bold;
  cursor: pointer;
}

.error_lable {
  font-size: 0.7rem;
  color: red;
  margin: 0;
}

/* page login */
.page_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  background-color: #583985;
  /* margin-bottom: var(--def_padding_top); */
}

.part1 {
  display: flex;
  flex: 8;
  justify-content: center;
  padding: 0 1rem;
  width: 100%;
}

.part1 img {
  max-width: 90%;
}

.part2 {
  width: 100%;
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page_title {
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0.5rem;

  text-transform: uppercase;
}

.page_sub_title * {
  color: #583985 !important;
}

.part2 button {
  background-color: #583985;
  border-color: #583985;
}

.part2 input {
  border-color: grey !important;
}

.part2 span {
  border-color: grey !important;
  border-left: none !important;
}

.password_container {
  display: flex;
  flex-direction: column;
}

.password_container input {
  border-right: none !important;
}

.page_form_container {
  width: 100%;
  min-height: 100px;
  padding: 1rem;
  border: 1px solid white;
  border-radius: 0.5rem;
  background-color: rgb(255, 255, 255);
}

.forgotPass {
  padding-top: 0.2rem;

  font-size: 0.9rem;
  font-weight: 600;
  text-decoration: none;
  align-self: flex-end;
}

@media screen and (max-width: 800px) {
  .page_title {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 700px) {
  .page_container {
    flex-direction: column;
  }

  .part1 {
    flex: 1 1 auto;
    align-items: center;
  }

  .part1 img {
    height: min-content;
    max-height: 300px;
  }

  .part2 {
    flex: 1 1 auto;
    align-items: center;
  }
}