.container {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  position: relative;

  font-size: 0.7rem;
  font-weight: 600;
  color: var(--main_color) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 120px;

  cursor: pointer;
  text-decoration: none;
}

.container i {
  margin-right: 0.1rem;
}

.detail_container {
  padding-top: 0.5rem;
  text-align: start;
  width: 100%;
}

.preorder_text,
.sold_out_text,
.closed_text {
  position: absolute;

  color: white;
  width: fit-content;
  background-color: grey;
  padding: 1px 5px;
  border-radius: 5px;
  font-size: 10px;
  text-transform: none;
}

.preorder_text {
  top: 0;
  right: 0;
}

.sold_out_text {
  right: 0;
  bottom: 0;
}

.closed_text {
  left: 0;
  bottom: 0;
}

.title {
  /* text-align: center; */
  font-weight: 600;
  /* color: black; */
  letter-spacing: 0;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: normal;
  overflow-wrap: break-word;
  font-size: 0.9rem;
  text-overflow: ellipsis;
}

.price {
  font-size: 0.8rem;
}

.discount {
  color: red;
  background-color: rgb(255, 0, 0, 0.2);
}

.discount_container * {
  font-size: 0.6rem;
}

.discount_container {
  display: flex;
  gap: 0.5rem;
}

.discount_container .price {
  color: grey;
  text-decoration: line-through;
}