* {
	line-height: normal !important;
}

.container {
	display: flex;
	width: 100%;
	height: 100%;
	gap: 0.5rem;
	padding: 0.5rem;
}

.container > * {
	width: 100%;
	border-radius: 0.5rem;
	height: 100%;
	padding: 0.5rem 5px;
}

.list_container {
	display: flex;
	flex-direction: column;
	gap: 0.1rem;
	max-width: 250px;
}

.list_body {
	margin-top: 0.5rem;
	border: 2px solid hsl(0, 0%, 83%);
	border-radius: 0.5rem;
	height: 100%;
	padding: 0.5rem;

	overflow-y: scroll;
	overflow-x: hidden;
}

.chat_container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 0.2rem;
}

.chat_header {
	display: flex;
	align-items: center;
	gap: 0.5rem;

	border-bottom: 1px solid hsl(0, 0%, 83%);
	padding: 0.5rem;
}

.header_identity {
	display: flex;
	gap: 0.5rem;
	width: 100%;
}

.header_identity img {
	height: 40px;
	width: 40px;
	border-radius: 50%;
}

.chat_body {
	background-color: hsl(0, 0%, 100%);
	height: 100%;
	border-radius: 0.5rem;
	padding: 0.5rem;

	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	overflow-x: hidden;
}

.chat_bubble {
	display: flex;
	flex-direction: column;
	max-width: 50%;

	--bubble_color: hsl(0, 0%, 83%);
	padding: 5px 0.5rem;
	background-color: var(--bubble_color);
	width: fit-content;
	margin-bottom: 5px;
	box-shadow: 1px 2px 2px hsl(0, 0%, 40%);
	position: relative;
}

.chat_bubble .chat_text {
	word-wrap: break-word;
}

.chat_left {
	border-radius: 0.3rem;
	border-top-left-radius: 0;
	margin-left: 0.5rem;
}

.chat_left span {
	font-size: 0.7rem;
	float: right;
}

.chat_left::before {
	content: "";
	border: 0.3em solid transparent;
	border-top-color: var(--bubble_color);
	border-right-color: var(--bubble_color);
	position: absolute;
	top: 0;
	right: 100%;
}

.chat_right {
	margin-right: 0.5rem;
	border-radius: 0.3rem;
	border-top-right-radius: 0;
	align-self: end;
}

.chat_right span {
	font-size: 0.7rem;
	float: left;
}

.chat_right::after {
	content: "";
	border: 0.3em solid transparent;
	border-top-color: var(--bubble_color);
	border-left-color: var(--bubble_color);
	position: absolute;
	top: 0;
	left: 100%;
}

.chat_footer {
	background-color: hsl(0, 0%, 83%);
	border-radius: 0.3rem;
}

.input_part {
	display: flex;
}

.input_part button {
	border: 1px solid hsl(0, 0%, 50%);
	border-left: none;
	border-radius: 0 0.3rem 0.3rem 0;
	background-color: hsl(0, 0%, 50%);

	color: white;
}

.input_part input {
	width: 100%;
	padding: 0.2rem 0.5rem;
	border: 1.5px solid hsl(0, 0%, 50%);
	border-right: none;
	border-radius: 0.3rem 0 0 0.3rem;

	outline: unset;
}

.image_btn {
	padding: 0.2rem 0.5rem;
	background-color: white;
	border: 1.5px solid hsl(0, 0%, 50%);
	border-right: none;
	border-left: none;
}

.image_btn > * {
	cursor: pointer;
}

.image_btn input {
	display: none;
}

/* group chat container */
.group_chat_container {
	border-radius: 0.5rem;
	padding: 0.5rem;
	max-width: 100%;

	--def_bg_room: hsl(0, 0%, 100%);
	--def_border_room: hsl(0, 0%, 70%);
	background-color: var(--def_bg_room);
	border: 1px solid var(--def_border_room);

	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 5px;
	cursor: pointer;
}

.group_chat_container:hover {
	box-shadow: 1px 1px 5px hsl(0, 0%, 50%);
}

.group_chat_container img {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: hsl(0, 0%, 100%);
}

.active_room {
	background-color: var(--def_border_room);
}

.chat_identity {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.chat_identity #name {
	font-weight: 600;
}

.chat_identity #small_text {
	font-size: 0.9rem;
}

#small_text,
#name {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.badge {
	padding: 0.2rem 0.5rem;
	background-color: red;
	text-align: center;
	border-radius: 0.5rem;

	font-size: 0.8rem;
	font-weight: 600;
	color: white;
}

/* action */
.action_container {
	position: relative;
}

.action_container .button {
	cursor: pointer;
}

.action_container .button i {
	font-size: 1.5rem;
}

.actMenu {
	display: inline-block;
	list-style: none;
	position: absolute;
	right: 0;
	z-index: 2;

	height: fit-content;
	margin-top: 0.3rem;
	padding: 0 5px;

	font-weight: 600;
	border-radius: 0.5rem;
	box-shadow: 2px 2px 3px grey;
	background-color: white;
}

.actMenu li {
	padding: 0.2rem 0.5rem;
	cursor: pointer;
	border-radius: 0.5rem;
	white-space: nowrap;
}

.actMenu li:first-child {
	margin-top: 0.2rem;
}

.actMenu li:last-child {
	margin-bottom: 0.2rem;
}

.actMenu li:hover {
	color: white;
	background-color: var(--main_color);
}

.down,
.up {
	max-height: 0;
	overflow: hidden;
	-webkit-transition: max-height 0.2s ease-in-out;
	-moz-transition: max-height 0.2s ease-in-out;
	-o-transition: max-height 0.2s ease-in-out;
	transition: max-height 0.2s ease-in-out;
}

.down {
	max-height: 500px;
	outline: 1px solid lightgrey;
}

.statfilterbtn {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0.3rem 0.5rem !important;
	margin-top: 0.2rem;
}
